import { ClerkApp } from "@clerk/remix";
import { rootAuthLoader } from "@clerk/remix/ssr.server";
import type { LoaderFunction, MetaFunction } from "@remix-run/node";
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLocation,
	useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { useEffect } from "react";
import "./tailwind.css";

export const meta: MetaFunction = () => {
	return [
		{
			title: "キリクリ - YouTube動画からショート動画を自動作成",
		},
		{
			name: "description",
			content:
				"AIを活用してYouTube動画から最適なハイライトを抽出し、ショート動画を生成します。",
		},
		{
			property: "og:title",
			content: "キリクリ - YouTube動画からショート動画を自動作成",
		},
		{
			property: "og:image",
			content: "https://kiricli.app/ogp.png",
		},
		{
			property: "og:description",
			content:
				"AIを活用してYouTube動画から最適なハイライトを抽出し、ショート動画を生成します。",
		},
		{ property: "og:url", content: "https://kiricli.app/" },
		{ property: "og:type", content: "website" },
		{ property: "og:site_name", content: "キリクリ" },
		{ name: "twitter:card", content: "summary" },
		{
			name: "twitter:image",
			content: "https://kiricli.app/twitter_card_ogp.png",
		},
		{
			name: "twitter:title",
			content: "キリクリ - YouTube動画からショート動画を自動作成",
		},
		{
			name: "twitter:description",
			content:
				"AIを活用してYouTube動画から最適なハイライトを抽出し、ショート動画を生成します。",
		},
	];
};

export const loader: LoaderFunction = (args) => rootAuthLoader(args);

const GA_MEASUREMENT_ID = "G-05PBNK94CF";

function usePageView(measurementId: string) {
	const location = useLocation();
	useEffect(() => {
		if (typeof window !== "undefined" && (window as any).gtag) {
			(window as any).gtag("config", measurementId, {
				page_path: location.pathname + location.search,
			});
		}
	}, [location, measurementId]);
}

function Document({ children }: { children: React.ReactNode }) {
	const organizationData = {
		"@context": "https://schema.org",
		"@type": "Organization",
		name: "キリクリ (KIRICLI)",
		url: "https://kiricli.app/",
		logo: "https://kiricli.app/logo.svg",
		sameAs: ["https://x.com/kiricliApp"],
		description:
			"キリクリはYouTube動画をAIで分析・編集し、ショート動画を自動生成するサービスです。",
	};

	return (
		<html lang="ja">
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<Links />
				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationData) }}
				/>
				<script
					async
					src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
				></script>
				<script
					dangerouslySetInnerHTML={{
						__html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_MEASUREMENT_ID}', { send_page_view: false });
            `,
					}}
				/>
			</head>
			<body>
				{children}
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	);
}

function App() {
	usePageView(GA_MEASUREMENT_ID);
	return (
		<Document>
			<Outlet />
		</Document>
	);
}

export const ErrorBoundary = () => {
	const error = useRouteError();
	captureRemixErrorBoundaryError(error);
	return <div>Something went wrong</div>;
};

export default ClerkApp(App);
